const initialState = {
  userRole: {
    isLogin:  true,
    role: "end-user",
  },
  isLoading: false,
};

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Role":
      return {
        ...state,
        userRole: action.payload,
      };
    case "LoadingOverlay":
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
