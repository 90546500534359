import "./App.css";
import Header from "./MasterComponent/Header";
import "./index.css";
import SideBar from "./MasterComponent/SideBar";
import Routing from "./MasterComponent/Routing";
import { useState } from "react";
import Login from "./MasterComponent/Login";
import { connect } from "react-redux";
import { SetLoadingOverlay } from "./Redux/Action";
import { loginRequest } from "./authConfig";
import { useMsal } from "@azure/msal-react";
import { CommonLoader } from "./MasterComponent/Overlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackgroundImg from "./Assets/background.jpg";

function App(props) {
  const [isCollapse, setIsCollapse] = useState(true);
  const { instance, accounts } = useMsal();

  console.log(accounts, "accounts");

  const handleLogin = () => {
    props.SetLoadingOverlay(true);
    instance
      .loginPopup(loginRequest)
      .then((e) => {
        console.log(e, "result");
      })
      .catch((e) => {});
    props.SetLoadingOverlay(false);
  };

  const handleLogOut = async () => {
    props.SetLoadingOverlay(true);
    instance.initialize();
    await instance.logoutRedirect({ account: instance.getActiveAccount() });
    props.SetLoadingOverlay(false);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ width: "auto" }}
      />
      {props.isLoading && <CommonLoader />}

      
      {
      window.location.href.toLowerCase().endsWith('external/retrofit') ?
        <Routing/>
      : (
        
      (accounts && accounts.length > 0) ? (
        <>
        <Header accounts={accounts} handleLogOut={handleLogOut} />  
        <div
          className="absolute top-[118px] flex w-full overflow-auto"
          style={{ height: "calc(100% - 119px)",
            backgroundImage: `url(${BackgroundImg})`
           }}
        >
          <div className={`${isCollapse ? "w-56" : "w-12"} fixed h-full`}>
            <SideBar isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
          </div>
          <div
            style={              
              isCollapse
                ? { width: "calc(100% - 224px)" }
                : { width: "calc(100% - 48px)" }
            }
            className={`p-5 absolute h-full ${
              isCollapse ? "left-[224px]" : "left-[48px]"
            } h-full`}
          >
            <div
              className="w-full h-full p-5 overflow-auto"
            style={{backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",              
            }}>
            <Routing />
            </div>
          </div>
        </div>
        </>
      ) : (
        <>
        <Header accounts={accounts} handleLogOut={handleLogOut} />
        <div
          className="absolute top-[118px] flex w-full overflow-auto"
          style={{ height: "calc(100% - 119px)",
            backgroundImage: `url(${BackgroundImg})`
           }}
        >
          <div className="h-[calc(100% - 25px)] w-full"
          style={{ backgroundColor:"rgba(255,255,255, .7)",
            margin:"25px",
            padding:"15px",
            borderRadius:"10px",
            boxShadow:"0px 0px 10px 0px rgba(0,0,0,0.75)",
          }}>
        <Login handleLogin={handleLogin} />
        </div>
        </div>
        </>
      ))}

    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
