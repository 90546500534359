export const getCrossReference = async (manufacturer) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/CrossReference/GetCrossReference?manufacturer=${manufacturer}`
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
}

export const getManufacturers = async () => {
  try {    
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/CrossReference/GetManufacturers`
    );
    
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getMainMenuItems = async (emailId = null) => {
  try {
    emailId = "patrick@slkva.com";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Services/GetMainMenuItems?EmailAddress=${emailId}`
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getUserTypes = async () => {
  try {
    // let token = localStorage.getItem("token");
    // const headers = new Headers();
    // const bearer = `Bearer ${token}`;

    // headers.append("Authorization", bearer);
    // const options = {
    //   method: "GET",
    //   headers: headers,
    // };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/Users/GetUserTypes`
    );
    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const createNewUser = async (body) => {
  // let token = localStorage.getItem("token");
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/Fusion/Users/Add`,
      requestOptions
    );
    let responseObject = {
      statusCode: response.status,
      message: await response.json(),
      ok: response.ok,
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
};