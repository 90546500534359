import LocksmithImg from "../Assets/Locksmith-man.png";
import OfficeImg from "../Assets/OfficeCorridoor.png";

const ProgramSelector = (props) => {

    return (                <>
    <div id="initialSelection">
        <div>
            <p className="psHeader" style={{fontSize:"36px"}}>Medeco Program Selector</p>
            <p className="psCopy" style={{marginBottom:"10px", marginTop:"10px"}}>Welcome to the Medeco Program Selector! If you are an end user, please select the "End User" tile. If you are a dealer or wholesaler, please select the "Reseller" tile.</p>
        </div>
        <div className="psContainer">
            <img className="psImage" src={OfficeImg} />
            <p className="psHeader">End User Program Selector</p>
            <p className="psCopyText">If you are an end user, please use this program selector by clicking on the link below</p>
            <button className="psButton" onClick={() => showSelector(1)}>Visit End User Program Selector &#62;</button>
        </div>
        <div className="psContainer">
            <img  className="psImage" src={LocksmithImg} />
            <p className="psHeader">Reseller Program Selector</p>
            <p className="psCopyText">If you are a dealer or wholesaler, please use this program selector</p>
            <button className="psButton" onClick={() => showSelector(2)}>Visit Reseller Program Selector  &#62;</button>
        </div>
    </div>
    <div id="iframeContainer" className="h-full" style={{display:"none"}}></div>
<script>
    
</script>
    </>
    )
};

function showSelector(id) {
    document.getElementById('initialSelection').style.display = 'none';
    document.getElementById('iframeContainer').style.display = 'inline';
    if(id === 1) {
        document.getElementById('iframeContainer').innerHTML 
        = '<iframe src="https://service.excentos.com/assaabloy_medeco/DE/app_End_User_Program_Advisor/default.start?xcLocale=en_US" width="95%"   scrolling="Yes" style="position:absolute; top:-130px; height:calc(100% + 100px);" ></iframe>';
    } else {
        document.getElementById('iframeContainer').innerHTML 
        = '<iframe src="https://service.excentos.com/assaabloy_medeco/DE/app_Reseller_Program_Advisor/default.start?xcLocale=en_US" width="95%"   scrolling="Yes" style="position:absolute; top:-130px; height:calc(100% + 100px);" ></iframe>';
    }
    //document.getElementById('xc_application').style.paddingTop = '0px';
    
}

export default ProgramSelector;