import Index from "../MainComponent/Index";
import Retrofit from "../MainComponent/Retrofit";
import Login from "./Login";
import NotFound from "./NotFound";
import { Route, Routes } from "react-router-dom";
import ProgramSelector from "../MainComponent/ProgramSelector";


const Routing = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Index />} />
      {/* <Route exact path="internal/" element={<Index />} />
      <Route exact path="sales/" element={<Index />} /> */}
      <Route exact path="external/retrofit" element={<Retrofit />} />
      <Route exact path="external/cross-reference" element={<Retrofit />} />  
      <Route exact path="external/program-selector" element={<ProgramSelector />} />
      <Route
        exact
        path="user/training-&-resources"
        element={<Index />}
      >
       
      </Route>
      <Route exact path="user/training-&-resources/key-schedule" element={<Index />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
