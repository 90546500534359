import { configureStore } from "@reduxjs/toolkit";
import { CommonReducer } from "./Reducer";

export const SetRole = (isLogin, role) => {
  return {
    type: "Role",
    payload: { isLogin: isLogin, role: role },
  };
};

export const SetLoadingOverlay = (isLoading) => {
  return {
    type: "LoadingOverlay",
    payload: isLoading,
  };
};

export const Store = configureStore({
  reducer: CommonReducer,
});
