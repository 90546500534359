import { BrowserRouter, NavLink } from "react-router-dom";
import medeco from "../Assets/Logos/FusionLogo.png";
import assaabloy from "../Assets/Logos/assaabloy.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { SetRole } from "../Redux/Action";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";

const Header = (props) => {
  console.log(props.userRole.isLogin, props.userRole.role, "redux");
  return (
    <header className=" fixed w-full z-10">
      <div className="bg-[#000000] flex justify-between items-center headerRow">
        <img alt="assa-logo" src={assaabloy} className="assaLogo" />
      </div>
      <div className="bg-[#ffffff] flex justify-between items-center">
        <a href="../">
        <img alt="medeco-logo" src={medeco} className="fusionLogo" />
        </a>
        {props.accounts && props.accounts.length > 0 && (
          <div>
            <span className="font-semibold text-size">
              Hello, {props.accounts[0].name}
            </span>
            <NavLink to={"/"} onClick={() => props.SetRole(false, "")}>
              <span className="px-2">
                <IconButton onClick={() => props.handleLogOut()}>
                  <LogoutIcon />
                </IconButton>
              </span>
            </NavLink>
            <TextsmsOutlinedIcon className="mr-4 cursor-pointer" />
          </div>
        )}
      </div>
      <div className="bg-[#968049] flex justify-between items-center headerRow"></div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetRole: (isLogin, role) => dispatch(SetRole(isLogin, role)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
