import RetrofitTable from '../kendoTable/RetrofitTable';

const Retrofit = (props) => {

    return (                
            <RetrofitTable />        
    )
};

export default Retrofit;
