import medeco from "../Assets/Logos/Medeco.png";
import AgreementsImg from "../Assets/CenterContent/Agreements.png";
import CreateMasterKeySystemImg from "../Assets/CenterContent/Create-Master-Key-System-1024.png";
import ROAPortalImg from "../Assets/CenterContent/ROA-Portal-1024.png";
import CustomCoiningImg from "../Assets/CenterContent/Custom Coining.jpg";
import VisualKeyControlImg from "../Assets/CenterContent/Visual Key Control.jpg";
import KeyRecordsManagementPortalImg from "../Assets/CenterContent/Other-Medeco-Icons-Checklist Images-Electronic-Key-Records.jpg";
import { connect } from "react-redux";
import "../Styles/indexstyling.css";


const Index = (props) => {

    return (
        <div class="sectionContainer">
            <div class="section topSection">
                <div class="sectionHeader topSectionHeader"> Welcome to Medeco!</div>
                <div class="leftSectionOnly topLeftSection">Thank you for your partnership with Medeco! The steps below outline how to get your key system up and running. If you have any questions, feel free to reach out to Medeco's Customer support team at 1-800-839-3157 or by emailing customerservice.medeco@assaabloy.com.</div>
            </div>

            <div class="section">
                <div class="sectionHeader">Step 1: Contracts/Key Control Agreements</div>
                <div class="leftSection">
                    Opening orders requiring a new contracted keyway must have all contract paperwork completed.
                    <br /><br />
                    DSS representatives can click here to visit the agreement form center. If you need an account for the agreement processing center or have questions, email Rhonda.Loyd2@assaabloy.com.
                </div>
                <div class="rightSection">
                    <img src={AgreementsImg} />
                </div>
            </div>
            <div class="section">
                <div class="sectionHeader">Step 2: Create Master Key System</div>
                <div class="leftSection">Once agreements are in place, it's time to design a key system. To assist you with designing a key system, watch the video below, read the info sheet, or contact Medeco's Master Key Department at medecomasterkey@assaabloy.com.</div>
                <div class="rightSection">
                    <img src={CreateMasterKeySystemImg} />
                </div>
            </div>

            <div class="section">
                <div class="sectionHeader">Step 3: ROA Portal</div>
                <div class="leftSection">
                    <p class="extraBold">**Do you have your registry number? If yes, proceed. If no, contact Medeco.**</p>
                    <br /><br />
                    An ROA or LOA is required for any end-user or factory restricted keyway, if the end-user has not already supplied or established one. The LOA or ROA can be sent with either an expiration date, tied to a specific order, or valid until further notice date. If a Record of Authorization has not been established, the end-user can register on the ROA portal at roa.medeco.com, and electronically fulfill the authorization requirements to purchase the product through one of our Medeco dealers.
                    <br /><br />
                    If custom coining is needed, make sure it is specified on the order.
                </div>
                <div class="rightSection">
                    <img src={ROAPortalImg} />
                </div>
            </div>

            <div class="section">
                <div class="sectionHeader">Step 4: Custom Coining</div>
                <div class="leftSection">If you desire custom coining on cut keys, please specify this request on the purchase order. If theorder does not specify, the keys will not be custom coined. When a coining die is purchased fora new contract, the agreement does not ensure the cut keys will be custom coined, unless it isspecified in the purchase order and an additional fee is applied.</div>
                <div class="rightSection">
                    <img src={CustomCoiningImg} />
                </div>
            </div>

            <div class="section">
                <div class="sectionHeader">Step 5: Key Serialization</div>
                <div class="leftSection leftSectionOnly">If key serialization is desired, please specify on the purchase order. If the specific numbers to beused for serialization are not supplied, the key serialization will be completed using Medeco's existing records.</div>
            </div>

            <div class="section">
                <div class="sectionHeader">Step 6: Visual Key Control</div>
                <div class="leftSection">If cylinder stamping is desired, specify VKC or CKC on the purchase order for cylinder stamping.VKC indicates the face of the cylinder will be stamped, and CKC indicates the side or back of thecylinder will be stamped. It is standard to stamp cylinders with the key set that operates the lock.If anything other than the key stamp is desired, please specify the changes on the order.</div>
                <div class="rightSection">
                    <img src={VisualKeyControlImg} />
                </div>
            </div>

            <div class="section bottomSection">
                <div class="sectionHeader">Step 7: Key Records Management Portal</div>
                <div class="leftSection">
                    <p class="extraBold">COMING SOON!</p> The last step is to register for the Key Records Management Portal. This portal allows you to digitally request and receive key records (bitting lists, new key bittings, etc).
                    <br /><br />
                    NOTE: The login for the Key Records Portal is tied to the ROA Portal. Before attempting to register for the Key Records Portal, make sure your account is set up in the ROA Portal
                </div>
                <div class="rightSection">
                    <img src={KeyRecordsManagementPortalImg} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userRole: state.userRole,
    };
};

export default connect(mapStateToProps, null)(Index);
