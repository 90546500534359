import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import {
  MenuItem,
  Select,
  LinearProgress,
} from "@mui/material";

import {
  CustomNoRowsOverlay,
} from "../MasterComponent/Overlay";
import {
  getCrossReference,
  getManufacturers
} from "../CommonFunctions/ApiCalls";
import { connect } from "react-redux";

const Agreements = (props) => {
  const [isLoadGrid, setIsLoadGrid] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  var [rows, setCrossReference] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  useEffect(() => {
    getCrossReferenceData();
    getManufacturerList();
  }, []);

  var getCrossReferenceData = async (selectedManufacturer) => {
    await getCrossReference(selectedManufacturer)
      .then((data) => {
        if (data && data.length > 0) {
          setCrossReference(data);
        }
      }).catch((e) => console.log(e, "erro"));
  };

  const getManufacturerList = async () => {
    await getManufacturers()
      .then((data) => {
        if (data && data.length > 0) {
          setManufacturers(data);
        }
      }).catch((e) => console.log(e, "erro"));
  };

  const handleOnChange = (fieldKey, e) => {
    setErrorMessage(null);
  };



  const columns = [
    {
      field: "lockType",
      headerName: "LOCK TYPE",
      headerClassName: "bg-[#1222]",
      width: 210,
      sortable: true
    },
    {
      field: "series",
      headerName: "SERIES",
      sortable: true,
      headerClassName: "bg-[#1222]",
      width: 210,
      filterRow: {
        visible: true,
        applyFilter: "auto",
      },
      searchPanel: {
        visible: true,
        placeholder: "Search...",
      },
      headerFilter: {
        visible: true,
      },
    },
    {
      field: "oemModel",
      headerName: "OEM MODEL",
      sortable: true,
      width: 210,
      headerClassName: "bg-[#1222]"
    },
    {
      field: "oemPartNumber",
      headerName: "OEM PART",
      sortable: true,
      headerClassName: "bg-[#1222]",
      width: 210,
    },
    {
      field: "function",
      headerName: "FUNCTION",
      sortable: true,
      headerClassName: "bg-[#1222]",
      width: 210,
    },
    {
      field: "trim",
      headerName: "TRIM",
      sortable: true,
      headerClassName: "bg-[#1222]",
      width: 210,
    },
    {
      field: "medecoPartNumber",
      headerName: "MEDECO PART",
      sortable: true,
      headerClassName: "bg-[#1222]",
      width: 280,
    },
    {
      field: "keymarkPartNumber",
      headerName: "KEYMARK PART",
      sortable: true,
      width: 200,
      headerClassName: "bg-[#1222]",
    }
  ];

  return (
    <div>
      <div className="">
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-72`}
          sx={{ fontSize: "small" , 
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal"
            }}}
          onChange={(e) => {
            //handleOnChange("agreementTypeId", e);        
            rows = [];
            getCrossReferenceData(e.target.value);
          }}
        >
          <MenuItem value={"SELECT"} 
              sx={{ fontSize: "small" }} 
              selected={"selected"}
              hidden={"hidden"}>
            Select a Manufacturer. . .
          </MenuItem>
          {manufacturers.map((manufacturer) => {
            return (
              <MenuItem value={manufacturer} sx={{ fontSize: "small" }}>
                {manufacturer}
              </MenuItem>
            );
          })}
        </Select>
      </div>

      <div className="pt-4 h-[670px]" style={{maxWidth:"1740px"}}>
        <DataGrid
          rowHeight={90}
          sx={{
            fontSize: "16px",
          }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          slots={{

            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, null)(Agreements);
